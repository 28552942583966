export const categories = [
  {
    id: 'l',
    name: 'left',
    weight: -2
  },
  {
    id: 'll',
    name: 'lean left',
    weight: -1
  },
  {
    id: 'c',
    name: 'center',
    weight: 0
  },
  {
    id: 'lr',
    name: 'lean right',
    weight: 1
  },
  {
    id: 'r',
    name: 'right',
    weight: 2
  },
];
